<template>
    <div class="identify">
        <div class="noIdentify" v-if="!isRealName && hasConfirmed">
            <div class="QRbox">
                <div id="identifyqrcode" style="width:180px;height:180px;"></div>
            </div>
            <div class="tip">扫描上方二维码进入手机端认证页面</div>
        </div>
        <div class="hasIdentified" v-else-if="hasConfirmed">
            <div class="icon">
                <img src="../../../assets/images/common/success.png" alt="">
            </div>
            <p>已认证</p>
            <p>{{name}}（{{idCard}}）</p>
            <p>证件照片：已上传</p>
            <p class="footer"><el-button @click="dialogVisible = true">解除实名信息绑定</el-button></p>
        </div>
        <el-dialog
            custom-class="unboundDialog"
            title=""
            :visible.sync="dialogVisible"
            width="400px">
            <p><svg-icon icon-class="ic-ts"></svg-icon></p>
            <p class="title">提示</p>
            <div class="content">
                <p>请谨慎操作，解绑后可重新绑定新用户信息，</p>
                <p>并退出登录，是否解绑 ？</p>
            </div>
            <div class="footer">
                <el-button type="primary" @click="confirmUnbound">解除实名信息绑定</el-button>
                <el-button class="cancel" @click="dialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */
import {mapState} from 'vuex'
import MD5 from 'js-md5'
import QRCode  from "qrcodejs2"
import conf  from '@/config/url'
export default {
    name:'identify',
    data:() => {
        return{
            theme:'',
            isRealName:false,
            hasConfirmed:false,
            dialogVisible:false,
            h5domain:''
        }
    },
    computed:{
        ...mapState({
            phoneNumber:state => state.userInfo.phone,
            name:state => state.subUserInfo.name && ('*'+ state.subUserInfo.name.charAt(state.subUserInfo.name.length-1)),
            idCard:state => state.subUserInfo.idCardNo
        }),
        /* 行业标签 
            meet_emergency应急
        */
        branchIndustry() {
            return this.$store.state.dynamic.pageSetData.branchIndustry
        },
    },
    watch: {
        isRealName(val){
            if(val){
                this.findUser()
                clearInterval(this.timer)
            }
        }
    },
    mounted(){
        this.theme = localStorage.getItem('theme')
        // 轮询获取实名认证状态
        this.postIsRealNameAuthentication()
        this.getAuthStaus()
    },
    methods:{
        getAuthStaus(){
            this.timer = setInterval(() => {
                this.postIsRealNameAuthentication()
            }, 2000)
        },
        findUser(){
            this.$api.usercenter.findUser().then(res => {
                if(res.success && res.data){
                    this.$store.commit('saveSubUserInfo',res.data)
                    localStorage.setItem('subUserInfo',JSON.stringify(res.data))
                }
            })
        },
        postIsRealNameAuthentication () {
            this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
                const m = `${localStorage.getItem('token')}true`
                this.isRealName = res.data === MD5(m)
                // console.log('identify',this.isRealName)
                
                this.hasConfirmed = true//避免闪屏
                this.$nextTick(() => {
                    !this.isRealName && this.qrcode()
                })
            })
        },
        async getSaasBranchDomain(){
            await this.$api.usercenter.getSaasBranchDomain(localStorage.getItem('branchId')).then((res) => {
                if (res.success && res.data && res.data.length) {
                    let h5domain = ''
                    res.data.forEach((item) => {
                        if (item.branchDomain) {
                        if (item.pcOrH5 === 1) {
                            h5domain = item.branchDomain;
                            this.h5domain = h5domain || '';
                        } 
                        }
                    })
                }
            })
            localStorage.setItem('rensheH5Domain',this.h5domain)
            return this.h5domain
        },
        async qrcode () {
            let domainArr = localStorage.getItem('domain')
            let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
            let jumpRoute = 'login'
            if(this.branchIndustry === 'meet_emergency'){// 应急定制
                jumpRoute = 'yj_training_options'
            }
            if(localStorage.getItem('theme') == 'renshe'){ //人社定制
                if(localStorage.getItem('rensheH5Domain')){
                    domainUrl = localStorage.getItem('rensheH5Domain');
                }else{
                    domainUrl = await this.getSaasBranchDomain();
                }
            }
            if(this.QRCode) return
            this.QRCode = new QRCode('identifyqrcode', {
                width: 180,
                height: 180,        // 高度
                // text:  `https://${domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.phoneNumber}`,   // 二维码内容
                text:  `${window.location.protocol}//${domainUrl}/${jumpRoute}?phone=${this.phoneNumber}&realName=1`,   // 二维码内容
                display: 'none'
                // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                // background: '#f0f',   // 背景色
                // foreground: '#ff0'    // 前景色
            })
        },
        async confirmUnbound(){
            const res = await this.$api.usercenter.cancleAuth()
            if(res.success){
                // 解绑成功，退出登录
                this.logout()
            }
        },
        async logout(){
            const params = { token: localStorage.getItem('token') }
            await this.$api.usercenter.postAccounLogout({ params })
            const branchId = localStorage.getItem('branchId')
            const saasId = localStorage.getItem('saasId')
            sessionStorage.clear()
            localStorage.clear()
            localStorage.setItem('branchId', branchId)
            localStorage.setItem('saasId', saasId)
            this.$router.push('/login');
        }
    },
    beforeDestroy(){
        clearInterval(this.timer)
    }
}
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
    .noIdentify
        .QRbox
            width 240px
            height 240px
            margin 0 auto
            margin-top 196px
            border 1px solid #F0F0F0
            border-radius 4px
            background #ffffff
            display flex
            justify-content center
            align-items center
        .tip
            margin-top 16px
            text-align center
            font-size 20px
            color #333
            line-height 28px
    .hasIdentified
        .icon
            margin 0 auto
            margin-top 196px
            width 150px
            height 150px
            >img 
                display block
                width 100%
                height 100%
        >p  
            margin-top 4px
            font-size 16px
            color #333333
            text-align center
            line-height 22px
        >p:first-of-type
            margin-top 8px
            margin-bottom 16px
            font-size 24px
            color #20D14A
            line-height 33px
        .footer
            text-align center
            margin-top 32px
            .el-button {
                width 200px
                height 40px
                color: #666666;
                border-color #D7DCEE
                padding 9px 20px
                /deep/ >span {
                    font-size 16px
                }
            }
    /deep/ .unboundDialog
        .el-dialog__header {
            .el-icon-close {
                font-weight 600
            }
        }
        .el-dialog__body {
            padding 10px 40px 30px
            text-align center
            .icon {
                font-size 50px
            }
            .title {
                margin-top 19px
                margin-bottom 16px
                font-size: 24px;
                font-weight: 600;
                color: #1B2257;
                line-height: 34px;
            }
            .content >p {
                font-size: 16px;
                color: #666666;
                line-height: 24px;
            }
        }
        .footer
            margin-top 30px
            .el-button {
                display block
                width: 240px;
                height: 40px;
                margin 0 auto
                padding 9px 20px
                >span {
                    font-size 16px
                }
                &.cancel {
                    margin-top 16px
                    color: #666666; 
                    border-color #D7DCEE
                }
            }
</style>