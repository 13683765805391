<template>
	<div class="personal" :class="{ yingjiPersonal: theme === 'yingji' }">
		<div class="personal-title">
			<p>个人中心</p>
		</div>
		<div class="personal-content">
			<div class="avatar">
				<!-- <div class="avatarIcon" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"> -->
				<div class="avatarIcon">
					<img v-if="hasSettedAvatarUrl" :src="hasSettedAvatarUrl" alt="" />
					<img v-else src="../../../assets/images/common/userL.png" alt="" />
					<!-- <div class="mask" v-show="showMask" @click="uploadAvatarDialog = true">
						<span>
							{{ hasSettedAvatarUrl ? '更换头像' : '上传头像' }}
						</span>
					</div> -->
				</div>

                <div class="uploadBtn" @click="uploadAvatarDialog = true">
                    {{ hasSettedAvatarUrl ? '更换头像' : '上传头像' }}
                </div>

				<!-- <div class="name">
					<span class="span1">{{ name }}</span>
					<span class="span2" :class="{ isRealName }" @click="changeTab('身份认证')">{{ isRealName ? '已认证' : '未认证' }}</span>
				</div> -->
				<!-- <div class="learningTime">
					<svg-icon icon-class="learning_time"></svg-icon>
					<div class="record">
						<div class="left">
							<div class="left_top">
								<span>{{ learningTime.todayLearnTime }}</span>
								<span>h</span>
							</div>
							<div class="left_down">
								<span>今天学习</span>
							</div>
						</div>
						<div class="right">
							<div class="left_top">
								<span>{{ learningTime.totalLearnTime }}</span>
								<span>h</span>
							</div>
							<div class="left_down">
								<span>累计学习</span>
							</div>
						</div>
					</div>
				</div> -->
			</div>
			<el-tabs
				:tab-position="'left'"
				v-model="activetab"
				style="height: 200px; width:1200px;"
				class="outerTabs"
				@tab-click="changetab"
				:before-leave="handleChangeTab"
			>
				<el-tab-pane label="我的学习" name="我的学习" v-if="configArr.is_show_my_study">
					<span slot="label">
						<svg-icon icon-class="center-study"></svg-icon>
						我的学习
					</span>
					<learning v-if="activetab === '我的学习'"></learning>
				</el-tab-pane>
				<el-tab-pane label="我的证书" name="我的证书" v-if="configArr.is_show_my_certificate">
					<span slot="label">
						<svg-icon icon-class="center-certificate"></svg-icon>
						我的证书
					</span>
					<my-certificate @changeTab="changeTab" v-if="activetab === '我的证书'"></my-certificate>
				</el-tab-pane>
				<el-tab-pane label="我的订单" name="我的订单" v-if="configArr.is_show_my_order">
					<span slot="label">
						<svg-icon icon-class="center-order"></svg-icon>
						我的订单
					</span>
					<my-order v-if="activetab === '我的订单'"></my-order>
				</el-tab-pane>

				<el-tab-pane label="我的测评" name="我的测评" v-if="configArr.is_show_my_evaluation">
					<span slot="label">
						<svg-icon icon-class="center-eval"></svg-icon>
						我的测评
					</span>
					<evaluation v-if="activetab === '我的测评'"></evaluation>
				</el-tab-pane>
				<el-tab-pane label="我的资料" name="我的资料" v-if="configArr.is_show_my_profile">
					<span slot="label">
						<svg-icon icon-class="center-ziliao"></svg-icon>
						我的资料
					</span>
					<personalInfo @changeTab="changeTab" v-if="activetab === '我的资料'"></personalInfo>
				</el-tab-pane>
				<el-tab-pane label="身份认证" name="身份认证" v-if="configArr.is_show_identyfy">
					<span slot="label">
						<svg-icon icon-class="center-idcard"></svg-icon>
						身份认证
					</span>
					<identify v-if="activetab === '身份认证'"></identify>
				</el-tab-pane>
				<el-tab-pane label="账号安全" name="账号安全" v-if="configArr.is_show_account_safe">
					<span slot="label">
						<svg-icon icon-class="center-safe"></svg-icon>
						账号安全
					</span>
					<accountSafe v-if="activetab === '账号安全'"></accountSafe>
				</el-tab-pane>
				<el-tab-pane label="我的消息" name="我的消息" v-if="configArr.is_show_my_message">
					<span slot="label">
						<svg-icon icon-class="center-msg"></svg-icon>
						我的消息
						<span class="unread-circle" v-if="existUnreadStatus"></span>
					</span>
					<my-message v-if="activetab === '我的消息'" @updateMessageStatus="updateMessageStatus" @existUnread="existUnread"></my-message>
				</el-tab-pane>
				<el-tab-pane label="服务点评" name="服务点评">
					<span slot="label">
						<svg-icon :icon-class="activetab === '服务点评' ?'center-evaluate2' :'center-evaluate'"></svg-icon>
						服务点评
					</span>
					<platformEvaluation v-if="activetab === '服务点评'" ></platformEvaluation>
				</el-tab-pane>
				<el-tab-pane label="我的报证" name="我的报证" v-if="configArr.is_show_my_declareCert_profile">
					<span slot="label">
						<svg-icon icon-class="center-declareCert"></svg-icon>
						我的报证
					</span>
					<myDeclareCertProfile v-if="activetab === '我的报证'"></myDeclareCertProfile>
				</el-tab-pane>
			</el-tabs>
		</div>

		<!-- 头像上传 -->
		<el-dialog
			title="上传头像"
			class="uploadAvatarDialog"
			:visible.sync="uploadAvatarDialog"
			:close-on-click-modal="false"
			width="480px"
			:before-close="cancelUploadAvatar"
		>
			<div class="dialogBody" v-loading="uploadAvatarLoading" element-loading-text="正在上传">
				<el-upload
					class="avatar-uploader"
					ref="avatarUploader"
					accept="image/*"
					:action="action"
					:headers="headers"
					:show-file-list="false"
					:before-upload="handleAvatarBefore"
					:on-success="handleAvatarSuccess"
					:on-error="handleAvatarError"
				>
					<img v-if="avatarUrl || hasSettedAvatarUrl" :src="avatarUrl || hasSettedAvatarUrl" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div v-if="avatarUrl || hasSettedAvatarUrl" class="preView">
					<div class="imgWrapper">
						<img :src="avatarUrl || hasSettedAvatarUrl" alt="" />
					</div>
					<div class="txt">头像预览</div>
				</div>
			</div>
			<span slot="footer" class="dialogFooter">
				<el-button @click="cancelUploadAvatar">取 消</el-button>
				<el-button type="primary" @click="confirmUploadAvatar">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
/* eslint-disable */
import MD5 from 'js-md5'
import cercertificateIsShowStatus from './../../../utils/certificateMixin'
import config from '@/config/url'
import personalInfo from './personalInfo'
import identify from './identify'
import evaluation from './evaluation.vue'
import learning from './myLearning.vue' // 我的学习
import accountSafe from './accountSafe' //账号安全
import MyMessage from './my-message.vue' //我的消息
import MyCertificate from './my-certificate.vue' //我的证书
import MyOrder from './my-order.vue' //我的订单
import platformEvaluation from './platformEvaluation.vue'; // 服务点评
import myDeclareCertProfile from './myDeclareCertProfile.vue'; // 我的报证

export default {
	name: 'personalCenter',
	data() {
		return {
			// learningTime: {
			// 	todayLearnTime: '',
			// 	totalLearnTime: '',
			// }, //学习时长统计
			// showMask: false, //更换头像
			existUnreadStatus: false, //是否有未读消息
			bEvaluate: false,
			themeContent: '',
			isRealName: false,
			configArr: {
				is_show_my_profile: true,
				is_show_my_certificate: true,
				is_show_my_task: true,
				is_show_my_study: true,
				is_show_my_order: true,
                is_show_my_evaluation: true,
                is_show_identyfy: true,
                is_show_account_safe: true,
                is_show_my_message: true,
				is_show_my_declareCert_profile:false
			},
			stopChange: false,
			uploadAvatarLoading: false,
			action: config.API + '/resource/v1/resource/uploadImage',
			headers: {
				token: localStorage.getItem('token'),
			},
			// hasSettedAvatarUrl:'',
			avatarUrl: '',
			uploadAvatarDialog: false,
			contentHeight: 0,
			activetab: '我的资料',
		}
	},
	mixins: [cercertificateIsShowStatus],
	created() {
		this.initConfig()
		// this.existUnread();
		this.contentHeight = window.innerHeight - 80 - 76
	},
	components: {
		personalInfo,
		identify,
		evaluation,
		learning,
		accountSafe,
		MyMessage,
		MyCertificate,
		MyOrder,
		platformEvaluation,
		myDeclareCertProfile
	},
	computed: {
		theme() {
			return this.$store.state.theme
		},
		name() {
			return this.$store.state.subUserInfo.name
		},
		hasSettedAvatarUrl() {
			return this.$store.state.subUserInfo.headImage
        }
	},
	mounted() {
		this.themeContent = localStorage.getItem('theme')
		//根据保存的tab返回相应的tab
		if (sessionStorage.getItem('userCenterCurrentTab')) {
			this.activetab = sessionStorage.getItem('userCenterCurrentTab')
		}
		// csm配置跳转链接，跳转相应tab
		if (this.$route.query.userCenterCurrentTab) {
			this.activetab = this.$route.query.userCenterCurrentTab
		}
		// 是否实名认证
        this.postIsRealNameAuthentication()
		// // 学习时长统计
		// this.getAllLearnedData()
		if (localStorage.getItem('theme') === 'junmin' && localStorage.getItem('checkJunminIsSaveInfo') == 'false') {
			this.stopChange = true
			console.log('位置1', this.stopChange)
		} else if (localStorage.getItem('theme') === 'junmin' && localStorage.getItem('checkJunminIsSaveInfo') == 'true') {
			this.stopChange = false
			console.log('位置zxc', this.stopChange)
		}
	},
	methods: {
		postIsRealNameAuthentication() {
			this.$api.monitor.postIsRealNameAuthentication({}).then((res) => {
				const m = `${localStorage.getItem('token')}true`
				this.isRealName = res.data === MD5(m)
			})
		},
		// getAllLearnedData() {
		// 	this.$api.learning.getAllLearnedData().then((res) => {
		// 		this.learningTime = res.data
		// 		console.debug(this.AllLearnedData)
		// 	})
		// },
		// handleMouseenter() {
		// 	this.showMask = true
		// },
		// handleMouseleave() {
		// 	this.showMask = false
		// },
		handleChangeTab() {
			return !this.stopChange
		},
		// 子组件修改tab
		changeTab(val) {
			this.activetab = val
		},
		// 更改未读消息状态
		updateMessageStatus(data) {
			this.existUnreadStatus = data
		},
		// 获取是否有未读消息
		existUnread() {
			this.$api.message.existUnread().then((res) => {
				if (res.data) {
					this.existUnreadStatus = true
				} else {
					this.existUnreadStatus = false
				}
				this.$store.commit('unreadMessageState', this.existUnreadStatus)
			})
		},
		initConfig() {
			/*
                is_show_my_certificate:false,
                is_show_my_task:false,
                is_show_my_study:false,
                is_show_my_order:false,
                is_show_my_evaluation:false
            */
			let configArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'))
			configArr.forEach((item) => {
				switch (item.key) {
					case 'is_show_my_profile':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_certificate':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_task':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_study':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_order':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_evaluation':
						this.configArr[item.key] = item.value === 'false' ? false : true
                        break
                    case 'is_show_identyfy':
						this.configArr[item.key] = item.value === 'false' ? false : true
                        break
                    case 'is_show_account_safe':
						this.configArr[item.key] = item.value === 'false' ? false : true
                        break
                    case 'is_show_my_message':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_declareCert_profile':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					default:
						break
				}
			})
		},
		// 确认修改头像
		confirmUploadAvatar() {
			if (!this.avatarUrl) {
				this.$message.error('请上传头像')
				return
			}
			if (localStorage.getItem('theme') == 'shanghaijiaoda') {
				this.$api.usercenter.updateShjdUser({ headImage: this.avatarUrl }).then((res) => {
					if (res.success) {
						// this.hasSettedAvatarUrl = this.avatarUrl
						this.$message.success('修改成功')
						this.uploadAvatarDialog = false
						this.$store.commit('updateSubUserInfo', { key: 'headImage', value: this.avatarUrl })
						localStorage.setItem('saveSubUserInfo', JSON.stringify(this.$store.state.subUserInfo))
					}
				})
			} else {
				this.$api.usercenter.updateUser({ headImage: this.avatarUrl }).then((res) => {
					if (res.success) {
						// this.hasSettedAvatarUrl = this.avatarUrl
						this.$message.success('修改成功')
						this.uploadAvatarDialog = false
						this.$store.commit('updateSubUserInfo', { key: 'headImage', value: this.avatarUrl })
						localStorage.setItem('saveSubUserInfo', JSON.stringify(this.$store.state.subUserInfo))
					}
				})
			}
		},
		cancelUploadAvatar() {
			this.$refs.avatarUploader.abort()
			this.uploadAvatarLoading = false
			this.avatarUrl = ''
			this.uploadAvatarDialog = false
		},
		handleAvatarBefore(file) {
			const isImg = file.type.includes('image')
			if (!isImg) {
				this.$message.error('只能上传图片!')
				return false
			}
			this.uploadAvatarLoading = true
		},
		// 头像上传成功
		handleAvatarSuccess(res, file) {
			this.avatarUrl = file.response.data
			this.uploadAvatarLoading = false
		},
		handleAvatarError(err) {
			this.uploadAvatarLoading = false
			this.$message.error('头像上传失败')
		},
		findAndSetPayStatus(data) {
			this.Countdown = null
			const payNos = data || this.qrcodeState.payNo
			this.Countdown = setInterval(() => {
				this.$api.home.findAndSetPayStatus(payNos).then((res) => {
					if (res.data == true) {
						clearInterval(this.Countdown)
						this.type = 'success'
						sessionStorage.setItem('typeState', this.type)
						sessionStorage.removeItem('qrcodeState')
					} else if (res.code != 0) {
						clearInterval(this.Countdown)
						this.type = 'fail'
						sessionStorage.setItem('typeState', this.type)
						sessionStorage.removeItem('qrcodeState')
					}
				})
			}, 3000)
		},
		// 关闭弹框,清除已经生成的二维码
		closeCode() {
			this.paymentdialogVisible = false
			sessionStorage.setItem('dialogState', this.paymentdialogVisible)
			this.$refs.qrCodeDiv.innerHTML = ''
			clearInterval(this.Countdown)
			sessionStorage.removeItem('dialogState')
			sessionStorage.removeItem('typeState')
			sessionStorage.removeItem('qrcodeState')
		},
		// 生成二维码
		qrcodeimg(data) {
			this.paymentdialogVisible = true
			// 缓存弹框状态
			sessionStorage.setItem('dialogState', this.paymentdialogVisible)
			this.type = 'payment'
			// 缓存支付状态
			sessionStorage.setItem('typeState', this.type)
			setTimeout(() => {
				new QRCode(
					this.$refs.qrCodeDiv,
					{
						text: data.codeUrl,
						width: 150,
						height: 150,
						colorDark: '#333333', // 二维码颜色
						colorLight: '#ffffff', // 二维码背景色
						correctLevel: QRCode.CorrectLevel.H, // 容错率L/M/H
					},
					100
				)
			})
			this.findAndSetPayStatus(data.payNo)
		},
		applyInvoiceShow() {
			this.applyInvoice = false
		},
		changetab(tab) {
			var checkJunminIsSaveInfo = localStorage.getItem('checkJunminIsSaveInfo')
			this.stopChange = false
			this.activetab = tab.name
			sessionStorage.removeItem('userCenterCurrentTab') //移除当前保存的tab
		},
		myOrderList() {
			this.$api.certificate.myOrderList().then(({ data }) => {
				this.orderlist = data
				this.hasOrder = this.orderlist.length > 0
				this.countDown()
			})
		},
	},
}
/* eslint-disable no-new */
</script>
<style lang="stylus" scoped>
@import '../css/personal.styl'
</style>
