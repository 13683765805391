<template>
  <div class="myDeclareCertProfile">
    <div class="topTitle">
      <span>我的报证</span>
      <div class="longLine">
        <div class="shortLine"></div>
      </div>
    </div>
    <el-form
      class="form_c"
      :class="theme=='renshe'?'form_c_renshe':''"
      ref="myForm"
      :model="userInfo"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="证件照：" prop="idPhoto">
        <div class="img_wrap">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :headers="headers"
            accept=".jpg,.jpeg"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div class="avatar_wrap" v-if="userInfo.idPhoto">
              <img :src="userInfo.idPhoto" class="avatar" />
              <div class="mask">
                <span class="txt">重新上传</span>
              </div>
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="upload_tips" v-if="theme=='renshe'">
            照片要求：本人近期正面免冠彩色白底头像, 头部占照片尺寸的2／3，不着白色上衣,常戴眼镜的应配戴眼镜，白色背景无边框，人像清晰，层次丰富，神态自然，无明显畸变。<br />
            名称：照片采用jpg格式，并以本身身份证号命名（X一律使用大写），保证与电子表格中的身份证号一致。<br />
            照片规格：一寸免冠照片， 尺寸为33mm（高）×23mm（宽），分辨率350dpi，照片大小应在50KB左右，最小不小于20KB，最大不超过150KB。
          </div>
          <div class="upload_tips" v-else>
            注:发证书时需要上传证件照片<br />
            图片格式:jpg<br />
            图片尺寸:358*441<br />
            最大不能超过:150KB
          </div>
        </div>
      </el-form-item>
      <el-form-item label="邮寄地址：" prop="currAddress">
        <el-input
          v-model="userInfo.currAddress"
          placeholder="请输入邮寄地址"
          style="width: 320px"
          :maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="单位：" prop="workUnit">
        <el-input
          v-model="userInfo.workUnit"
          placeholder="请输入单位"
          style="width: 320px"
          :maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="saveBtn" type="primary" @click="onSubmit"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import config from "@/config/url";
export default {
  name: "myDeclareCertProfile",
  data() {
    return {
      theme:'',
      userId: "",
      userInfo: {
        userId: "",
        currAddress: "",
        workUnit: "",
        idPhoto: "",
      },
      action: config.API + "/resource/v1/resource/uploadPicture",
      headers: {
        token: localStorage.getItem("token"),
      },
      rules: {
        currAddress: {
          required: true,
          message: "请输入邮寄地址",
          trigger: "blur",
        },
        workUnit: { required: true, message: "请输入单位", trigger: "blur" },
        idPhoto: { required: true, message: "请上传证件照", trigger: "blur" },
      },
    };
  },
  async mounted() {
    this.theme = localStorage.getItem('theme');
    const userInfoRes = await this.$api.usercenter.findUser();
    if (userInfoRes.success) {
      this.userId = userInfoRes.data.id;
    }
    this.getReportInfo();
  },
  methods: {
    getReportInfo() {
      this.$api.usercenter.findReport({ userId: this.userId }).then((res) => {
        if (res.success) {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              const element = res.data[key];
              if (Object.hasOwnProperty.call(this.userInfo, key))
                this.userInfo[key] = element;
            }
          }
        }
      });
    },
    //图片上传
    handleAvatarSuccess(res) {
      this.userInfo.idPhoto = res.data;
      this.$refs.myForm.clearValidate("idPhoto");
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const nameArr = file.name.split(".");
      if (
        file.type !== "image/jpeg" ||
        (nameArr && !["jpg","jpeg","JPG","JPEG"].includes(nameArr[nameArr.length - 1]))
      ) {
        this.$message.error("请上传jpg图片");
        return false;
      }
      if (file.size / 1024 > 150) {
        this.$message.error("图片不能大于150KB");
        return false;
      }
      return true;
    },
    onSubmit() {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          const data = { ...this.userInfo, userId: this.userId };
          console.log(data);
          this.$api.usercenter.saveReport(data).then((res) => {
            if (res.success) {
              this.$message.success("保存成功");
              this.getReportInfo();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.topTitle {
  position: relative;
  height: 33px;
  padding-bottom: 12px;
  margin-bottom: 32px;

  >span {
    font-size: 24px;
    color: #316FFF;
    font-weight: 500;
  }

  text-align: center;

  .longLine {
    position: absolute;
    bottom: 0;
    right: -24px;
    width: 998px;
    height: 1px;
    background: rgba(49, 111, 255, 0.2);

    .shortLine {
      position: absolute;
      left: 50%;
      top: -3px;
      transform: translateX(-50%);
      width: 112px;
      height: 3px;
      background: #316FFF;

      &::after {
        content: '';
        display: inline-block;
        border: 9px solid transparent;
        border-top: 9px solid #316FFF;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.img_wrap {
  display: flex;
  align-items: center;
}
.avatar-uploader {
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/.el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar_wrap {
    width: 178px;
    height: 178px;
    position: relative;

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }

    .mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s;

      .txt {
        color: #fff;
        font-size: 18px;
      }
    }

    &:hover {
      .mask {
        opacity: 1;
      }
    }
  }
}

.upload_tips {
  line-height: 24px;
  font-size: 14px;
  color: #999;
  margin-left: 24px;
  flex-shrink: 0;
}
form.form_c_renshe{
  width:660px;
  .upload_tips{
    width:520px;
  }
  .avatar-uploader{
    height:180px;
    .avatar_wrap{
      width:128px;
    }
    .avatar-uploader-icon{
      width:128px;
    }
  }
}
.form_c {
  width: 404px;
  margin: 0 auto;

  /deep/ .el-form-item {
    margin-bottom: 16px;
  }

  /deep/ .el-form-item__label {
    padding: 0;
    font-size: 16px;
    color: #333;
  }

  .genderRadio {
    /deep/ .el-form-item__content {
      line-height: 40px;
    }

    .radioGroup {
      /deep/ .el-radio__label {
        font-size: 16px;
        color: #333;
      }
    }
  }

  .saveBtn {
    margin-top: 24px;
    width: 320px;
    height: 40px;
    background: #316FFF;
  }

  .identifyBtn {
    width: 100%;
    padding: 9px 20px;

    /deep/ >span {
      font-size: 16px;
      color: #666666;
      line-height: 22px;
    }
  }
}
</style>