<template>
    <div class="platformEvaluation">
        <p class="title">{{scoreInfo.name}}</p>
        <div class="rate">
          <el-rate
            v-model="rateValue"
            :disabled-void-color="'#E7E7E7'"
            :icon-classes="iconClasses"
            void-icon-class="icon-xingxing-kongxin"
            disabled-void-icon-class="icon-xingxing-kongxin"
            disabled
            allow-half
            
          ></el-rate>
          <p class="score">{{rateValue}}分</p>
          <p class="num">共{{userNum}}人做了评价</p>
        </div>
        <p class="tip">您的评分，我们的动力。</p>
        <p class="button" v-if="!issubmit" @click="handleEvaluation">评分</p>
        <!-- 评分弹框 -->
        <scoreDialog v-if="scoreDialogVisible.show" :scoreDialogVisible="scoreDialogVisible" :info="scoreInfo" :issubmit="issubmit"></scoreDialog>
    </div>
</template>

<script>
export default {
    name:'platformEvaluation',
    data(){
        return {
          rateValue:0,
          userNum:0,
          issubmit:false,
          iconClasses:[ 'icon-xingxing-kongxin', 'icon-xingxing-banxin', 'icon-xingxing-shixin'],
          scoreDialogVisible:{
              show: false,
          },
          scoreInfo:{
              title:'评分',
              name:'',
              scoreArr:[]
          }, // 评分需要的参数
          myScore:0,
        }
    },
    created(){
      if(localStorage.getItem('subUserInfo')){
        this.userInfo = JSON.parse(localStorage.getItem('subUserInfo'));
      }
      this.findMyBranchEvaluation()
    },
    methods:{
        // 获取当前评分
        async findMyBranchEvaluation(){
            await this.$api.platformEvaluation.findMyBranchEvaluation({}).then((res) => {
                if(res.data){
                    this.rateValue = res.data.avgScore;
                    this.userNum = res.data.userNum;
                    this.scoreInfo.name = res.data.branchName || '';
                    this.issubmit = res.data.branchEvaluationVoList.length ? true : false;
                    this.scoreInfo.scoreArr = []
                    res.data.branchEvaluationVoList.length && res.data.branchEvaluationVoList.forEach(item => {
                      this.scoreInfo.scoreArr.push({
                          label:item.evaluationItemName,
                          count:item.score
                      })
                    })
                }
            })
        },
        handleEvaluation(){
            if(!this.scoreInfo.scoreArr.length){
              this.scoreInfo.scoreArr = [
                  {
                    label:'服务态度',
                    count: 0,
                  },
                  {
                    label:'服务能力',
                    count: 0,
                  },
                  {
                    label:'服务效率',
                    count: 0,
                  }
              ]
            }
            this.scoreDialogVisible.show = true;
        },
        async evaluateCourse(arr){
            console.log(arr,'arr==');
            const data = [
                {
                  evaluationItem:'SERVICE_ATTITUDE',
                  evaluationItemName:'服务态度',
                  score:arr[0].count,
                },
                {
                  evaluationItem:'SERVICE_ABILITY',
                  evaluationItemName:'服务能力',
                  score:arr[1].count,
                },
                {
                  evaluationItem:'SERVICE_EFFICIENCY',
                  evaluationItemName:'服务效率',
                  score:arr[2].count,
                }
            ]
            await this.$api.platformEvaluation.branchEvaluationSave({ data }).then(res => {
                if(res.data){
                  this.clickclose()
                  this.findMyBranchEvaluation();
                }
                
            })
        },
        /** 评分 */
         clickclose(){
            this.scoreDialogVisible.show=false;
            this.$forceUpdate;
        },
    }
}
</script>

<style lang="less" scoped>
@import '../../../assets/css/font/iconfont.css';
.platformEvaluation{
    .title{
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 33px;
    }
    .rate{
        display: flex;
        align-items: center;
        .score{
            margin-left: 13px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
        }
        .num{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
          margin-left: 12px;
        }
    }
    /deep/.el-rate{
        height: auto;
    }
    /deep/.el-rate__icon{
        font-size: 24px;
        // margin-right: 0;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        font-family: iconfont !important;
    }
    /deep/.el-rate__decimal{
        font-style: inherit;
    }
    .tip{
      margin-top: 32px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }
    .button{
        margin-top: 38px;
        cursor: pointer;
        width: 88px;
        height: 32px;
        background: #316FFF;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 32px;
    }
}
</style>