<template>
    <div class="personalInfoFormNew">
        <el-form class="form" ref="form" :model="form" label-width="176px" :rules="rules">
            <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入姓名" style="width:320px" maxlength="15" show-word-limit></el-input>
            </el-form-item>
            <el-form-item class="sexRadio" label="性别：" prop="sex">
                <el-radio-group class="radioGroup" v-model="form.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                    <!-- <el-radio :label="3">保密</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄：" prop="age">
                <el-input
                    v-model="form.age"
                    placeholder="请输入年龄"
                    style="width:320px"
                    oninput="if(value>999){value=999}else{value=value.replace(/[^\d]/g,'')}if(value.indexOf(0)==0){value=0}"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="国籍：" prop="nationality">
                <el-input v-model="form.nationality" placeholder="请输入国籍" style="width:320px"></el-input>
            </el-form-item>
            <el-form-item label="证件类型：" prop="cardType">
                <el-select
                    disabled
                    v-model="form.cardType"
                    style="width:320px"
                    :loading="loading">
                    <el-option
                        v-for="item in cardtypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="证件号：" v-if="form.idCard" prop="idCard">
                <el-input v-model="form.idCard" disabled placeholder="请输入身份证号" style="width:320px"></el-input>
            </el-form-item>
            <el-form-item label="证件号：" v-else>
                <el-button class="identifyBtn" @click="toIdentify" style="width:320px">前往身份认证</el-button>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入联系电话" disabled style="width:320px"></el-input>
            </el-form-item>
            <el-form-item label="联系地址：" prop="regionCode">
                <el-select style="width:320px" v-model="form.regionCode" :remote-method="remoteMethod" :loading="areaData.loading" filterable remote clearable placeholder="请选择联系地址（模糊检索）">
                    <el-option
                        v-for="(item, index) in areaData.list"
                        :key="index"
                        :label="item.name"
                        :value="item.code"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="邮政编码：" prop="postCode">
                <el-input v-model="form.postCode" placeholder="请输入邮政编码" style="width:320px" @change="postCodeChange"></el-input>
            </el-form-item>
            <!-- <el-form-item label="照片：" class="photo-jiaotong" prop="photo">
                <img :src="form.photo" alt="">
            </el-form-item> -->
            <el-form-item label="申请培训类别：" prop="apCategory">
                <el-select
                    v-model="form.apCategory"
                    style="width:320px"
                    :loading="loading">
                    <el-option
                        v-for="item in apCategoryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="驾驶证准驾车型：" prop="drType">
                <el-select v-model="form.drType" placeholder="请选择驾驶证准驾车型" style="width:320px">
                    <el-option :label="item.label" :value="item.value" v-for="(item) in drTypeOptions" :key="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="从业资格证发证机关：" prop="iauthority">
                <el-input v-model="form.iauthority" placeholder="请输入从业资格证发证机关" style="width:320px"></el-input>
            </el-form-item>
            <el-form-item label="从业资格证件号码：" prop="qcNumber">
                <el-input v-model="form.qcNumber" placeholder="请输入从业资格证件号码" style="width:320px"></el-input>
            </el-form-item>
            <el-form-item label="从业资格证有效期至：" prop="monthrange">
                <el-date-picker
                    style="width:320px"
                    v-model="monthrange"
                    type="monthrange"
                    range-separator="-"
                    placeholder="选择从业资格证有效期至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="所属（服务）单位名称：" prop="insName">
                <el-input v-model="form.insName" placeholder="请输入所属（服务）单位名称" style="width:320px"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所属（服务）单位编号：" prop="insCode">
                <el-input v-model="form.insCode" placeholder="请输入所属（服务）单位编号" style="width:320px"></el-input>
            </el-form-item> -->
            <el-form-item>
                <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
      </el-form>
    </div>
</template>
<script>
import MD5 from 'js-md5'
import dayjs from 'dayjs'
import { phoneValidator } from '../../../../utils/validator';
export default {
    name:'personalInfoFormNew',
    data(){
        return{
            loading:false,
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            filterOptions:[],
            monthrange:[],
            cardtypeOptions:[
                { value: 1, label: '身份证' },
                { value: 2, label: '护照' },
                { value: 3, label: '军官证' },
                { value: 4, label: '其他' }
            ],
            apCategoryOptions:[
                { value:'0', label:'道路旅客运输'},
                { value:'1', label:'道路货物运输'},
                { value:'2', label:'危险货物道路运输'},
                { value:'3', label:'放射性物品道路运输'},
                { value:'4', label:'机动车检测维修'},
                { value:'5', label:'机动车驾驶培训'},
                { value:'6', label:'出租汽车运输'},
                { value:'7', label:'道路运输主要负责人'},
                { value:'8', label:'城市公共汽电车运输'},
                { value:'9', label:'城市轨道交通运输'},
                { value:'10', label:'其他道路运输'},
            ],
            drTypeOptions:[
                {label:'A1',value:'0'},
                {label:'A2',value:'1'},
                {label:'A3',value:'2'},
                {label:'B1',value:'3'},
                {label:'B2',value:'4'},
                {label:'C1',value:'5'},
                {label:'C2',value:'6'},
                {label:'C3',value:'7'},
                {label:'C4',value:'8'},
                {label:'C5',value:'9'},
                {labele:'无',value:'10'}
            ],
            userTypeOptions:{1:'教学资源提供者',2:'教学组织者',3:'远程教育平台运营服务人员',4:'学习者',5:'教学管理者'},
            form:{
                id:null,
                stuNum:'',//编号
                name:'',//姓名
                sex:'',//性别
                age:'',//年龄
                nationality:'',//国籍
                cardType:'',//证件类型
                idCard:'',//证件号
                phone:'',//联系电话
                regionCode:'',//联系地址
                address:'',//联系地址
                apCategory:'',//申请培训类别编号
                drType:'',//驾驶证车型
                postCode:'',//邮政编码
                photo:'',//人像信息
                iauthority:'',//从业资格机关
                qcNumber:'',//从业资格证件号码
                cvalidity:'',//从业资格证有效期
                applyDate:'',//注册时间
                userType:'',//用户类型
                insName:'',//所属企业名称
                insCode:'',//所属企业编号
            },
            rules:{
                name:[{required: true,message: '请输入姓名',trigger: 'blur',}],
                // nationality:[{required: true,message: '请选择性别',trigger: 'blur',}],
                idCard:[
                    {required: true,message: '请输入证件号',},
                    {
                        validator: this.idCardValidator,
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                    {
                        validator: phoneValidator,
                        trigger: 'blur',
                    },
                ],
            },
            areaList:[],
            areaData: {
                list: [],
                loading: false
            },
            isRealName:false
        }
    },
    async mounted(){
        this.postIsRealNameAuthentication()
        await this.getTree()
        this.getPersonalInfo()
    },
    methods:{
        postCodeChange(val){
            console.log('value',val);
            const isPostalcode = /^[1-9][0-9]{5}(?!\d)$/;
            console.log('isPostalcode.test(val)',isPostalcode.test(val))
            if (!isPostalcode.test(val)) {
                this.$message.warning('邮政编码格式不正确')
            }
        },
        // 我的资料
        postIsRealNameAuthentication () {
            this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
                if(res.success){
                    const m = `${localStorage.getItem('token')}true`
                    this.isRealName = res.data === MD5(m)
                }
            })
        },
        getPersonalInfo(){
            this.monthrange = []
            this.$api.usercenter.findByUserId().then(res => {
                if(res.code == 0){
                    this.form  = res.data
                    if(res.data.cvalidity){
                        this.monthrange.push(res.data.cvalidity.split('-')[0],res.data.cvalidity.split('-')[1])
                    }
                    this.remoteMethod('',res.data.regionCode)
                }
            })
        },
        remoteMethod(query,code){
            if (query !== '') {
                setTimeout(() => {
                    this.areaData.loading = false;
                    this.areaData.list = this.areaList.filter(item => {
                        return item.name.includes(query)
                    });
                }, 200);
            } else {
                this.areaData.list = this.areaList.filter(item => {
                    return item.code == code
                })
            }
        },
        //获取城市列表
        getTree(){
            if(this.$store.state.areaList.length>0){
                this.areaList = this.$store.state.areaList
            }else{
                return this.$api.usercenter.getTree().then(res => {
                    res.data.forEach(item => {
                        const L1Name = item.name
                        item.children && item.children.length && item.children.forEach(el => {
                            const L2Name = el.name
                            if(!el.children){
                                this.areaList.push({
                                    name: `${L1Name}-${L2Name}`,
                                    code: el.code,
                                    id: el.id
                                })
                            }else if(el.children.length){
                                el.children.forEach(v => {
                                    const L3Name = v.name
                                    this.areaList.push({
                                    name: `${L1Name}-${L2Name}-${L3Name}`,
                                    code: v.code,
                                    id: v.id
                                    })
                                })
                            }
                        })
                    })
                    this.$store.commit('saveCityTree',this.areaList)
                })
            }
        },
        onSubmit(){
            // this.form.regionCode = this.form.address
            this.form.applyDate = this.form.applyDate ? dayjs( this.form.applyDate ).format("YYYY-MM-DD HH:mm:ss") : null;
            this.form.cvalidity = dayjs( this.monthrange[0] ).format("YYYY/MM") + '-' + dayjs( this.monthrange[1] ).format("YYYY/MM");
            this.$api.usercenter.jiaotongSave(this.form).then(res => {
                if(res.success){
                    this.$message.success('修改成功')
                    this.updateUserInfo()
                }
            })
        },
        updateUserInfo(){
            this.$api.usercenter.findByUserId().then(res => {
                if(res.code == 0){
                    this.$store.commit('saveSubUserInfo',res.data)
                    localStorage.setItem('subUserInfo',JSON.stringify(res.data))
                }
            })
        },
        toIdentify(){
            this.$parent.$emit('changeTab','身份认证')
        }
    }
}
</script>
<style lang="stylus" scoped>
.form  
    width 604px
    margin 0 auto
    /deep/ .el-form-item
        margin-bottom 16px
    /deep/ .el-form-item__label
        padding 0
        font-size 16px
        color #333
    .sexRadio
        /deep/ .el-form-item__content
            line-height 40px
        .radioGroup
            /deep/ .el-radio__label
                font-size 16px
                color #333
    .saveBtn
        margin-top 24px
        width 320px
        height 40px
        background #316FFF
    .identifyBtn {
        width 100%
        padding 9px 20px
        /deep/ >span {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
        }
    }
.photo-jiaotong{
    img{
        width:320px;
        height:320px;
    }
}
</style>