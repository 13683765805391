<template>
    <div class="personalInfo">
        <div class="topTitle">
            <span>我的资料</span>
            <div class="longLine">
                <div class="shortLine"></div>
            </div>
        </div>
        <div class="content"> 
            <trafficPersonalInfoForm v-if="branchIndustry === 'traffic'"></trafficPersonalInfoForm>
            <personalInfoForm v-else></personalInfoForm>
        </div>
    </div>
</template>

<script>
import trafficPersonalInfoForm from '../page/traffic/personalInfoFormNew'
export default {
    name:'personalInfo',
    data(){
       return{}
    },
    components:{
        trafficPersonalInfoForm
    },
    computed:{
        theme(){
            return this.$store.state.theme
        },
        /* 行业标签 
            traffic  交通
        */
        branchIndustry(){
            return this.$store.state.dynamic.pageSetData.branchIndustry
            // return 'traffic'
        }
    },
    async mounted(){
        
    },
    methods:{
        
    }
}
</script>

<style lang="stylus" scoped>
    .topTitle
        position relative
        height 33px
        padding-bottom 12px
        margin-bottom 32px
        >span
            font-size 24px
            color #316FFF
            font-weight 500
        text-align center
        .longLine
            position absolute
            bottom 0
            right -24px
            width 998px
            height 1px
            background rgba(49,111,255,0.2)
            .shortLine
                position absolute
                left 50%
                top -3px
                transform translateX(-50%)
                width 112px
                height 3px
                background #316FFF
                &::after
                    content ''
                    display inline-block
                    border 9px solid transparent
                    border-top 9px solid #316FFF
                    position absolute
                    left 50%
                    top 10px
                    transform translate(-50%,-50%)
</style>